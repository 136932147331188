.home-page-container {
  width: 100%;
  background: var(--bg);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding-top: 2em;
}

.home-section-container {
  width: 60%;
  border: 3px solid var(--primary);
  border-radius: 12px;
  margin: 1em !important; 
  padding: 2em;
  background: white;
}

.upcoming-events-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  overflow-y: hidden;
}

.upcoming-event-container {
  min-width: 13vw !important;
  min-height: 20vh !important;
  background: var(--primary-gradient);
  border-radius: 8px;
  color: white;
  margin: .25em;
  padding: .25em .5em;
}

@media (max-width: 768px) {
  .upcoming-event-container {
    min-width: 40vw !important;
    min-height: 20vh !important;
    background: var(--primary-gradient);
    border-radius: 8px;
    color: white;
    margin: .25em;
    padding: .5em;
  }

  .home-section-container {
    width: 90%;
    padding: 1em;
  }
}

.details-button {
  background: white !important;
  color: var(--primary) !important;
  border: none !important;
  border-radius: 4px !important;
  padding: .25em 1.5em !important;
}

.details-button:hover {
  background: transparent !important;
  border: 1px solid white !important;
  color: white !important;
}

.home-icon {
  height: 100px;
  width: 100px;
  padding: 1em;
}

.home-icon-container {
  height: 100px;
  width: 100px;
  background: var(--primary-gradient);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-icon-text {
  background: var(--primary-gradient);
  color: white;
  padding: .5em;
  border-radius: 20px;
  width: 120px;
  text-align: center;
  margin-top: -.5em;
}

.home-column {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.home-icon-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 11px;
}

.home-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 1em;
}

.home-action-button {
  background: var(--accent) !important;
  color: white !important;
  border: none;
  padding: .25em 2em !important;
  border-radius: 8px !important;
  cursor: pointer;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}