.customer-form-container {
  padding-top: 1%;
  width: 100%;
  display: flex;
  justify-content: center;
}

.customer-form-wrapper {
  background-color: white;
  border-radius: 12px;
  border-bottom: 2px solid var(--secondary);
  width: 60vw;
}

.customer-form {
  text-align: center;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 2em;
}

.label {
  margin-bottom: .5em;
}

.input {
  margin-bottom: 2em;
  text-align: center;
}

.submit {
  margin-bottom: 1em;
  background-color: var(--primary-gradient) !important;
}

.customer-events-table-container {
  padding: 2em;
}

.customer-events-table td {
  padding: 1em !important;
}

.customer-events-table th {
  padding: 1em !important;
}

.managers-table {
  width: 20vw;
}

.managers-table td{
  font-size: 18px;
}

@media (max-width: 768px) {
  .managers-table {
    width: 100%;
  }
  .customer-events-table-container {
    padding: 0;
  }
  .customer-form {
    width: 90%;
  }
}
