.profile-container {
  background-color: var(--bg);
  margin: 0;
  width: 100%;
  padding: 2em 20em;
}

.profile-picture-container {
  border: 1px solid var(--secondary);
  background-color: lightgray;
  height: 100%;
  border-radius: 8px;
  padding: 7em 2em;
  width: 25% !important;
  height: 20em;
  margin-right: 2%;
}

.details-container {
  background-color: white;
  border: 3px solid var(--primary);
  border-radius: 8px;
  padding: 2em 2em !important;
}

.next-events-container {
  background-color: white;
  border: 3px solid var(--primary);
  border-radius: 8px;
  padding: 1em;
  margin-top: 2em !important;
}

.stats-container {
  background-color: white;
  border: 3px solid var(--primary);
  border-radius: 8px;
  padding: 1em;
  margin-top: 2em !important;
}

.past-events-container {
  background-color: white;
  border: 3px solid var(--primary);
  border-radius: 8px;
  padding: 1em;
  margin-top: 2em !important;
}

.stats-chart {
  border: 2px solid var(--primary);
  border-radius: 12px;
  padding: 1em;
  margin-left: 2em;
  margin-right: 2em;
}

.details-section {
  border-right: 2px solid var(--secondary);
}

.medals-section {
  margin-left: 1em;
}

.medal-icon {
  height: 3em;
  width: 3em;
}

@media screen and (max-width: 768px) {
  .profile-container {
    padding: 2em 1em !important;
  }

  .profile-picture-container {
    width: 100% !important;
    margin-right: 0;
  }

  .details-container {
    width: 100% !important;
    padding: 1em 1em !important;
  }

  .next-events-container {
    margin-top: 1em !important;
  }

  .stats-container {
    margin-top: 1em !important;
  }

  .past-events-container {
    margin-top: 1em !important;
  }

  .stats-chart {
    margin-left: 0;
    margin-right: 0;
  }                     
  
}