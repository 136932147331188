
.register-container {
  width: 100vw;
  height: 100vh;
  background: var(--primary-gradient);
  margin: 0 !important;
}

.register-form {
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 2em;

  input {
    margin-bottom: 0 !important;
  }
}


.label {
  margin-bottom: .5em;
}

.input {
  width: 35%;
  margin-bottom: 1em;
  text-align: center;
}

.input.invalid {
  border: 2px solid red !important;
}

.submit-accent {
  margin-top: 1em;
  border: none !important;
  height: auto;
  width: 50%;
  background-color: var(--accent) !important;
}

.error-message {
  color: red;
}

@media (max-width: 768px) {
  .label {
    color: white;
  }
}