.playground-container {
  display: flex;
  flex-direction: row;
  margin: 1% !important;
  justify-content: space-between;
  align-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
}

.playgroups-container {
  display: flex !important;
  align-items: flex-start !important;
}

.brackets-container {
  display: flex !important;
  flex-direction: row !important;
  margin: 1em;
  width: 100%;
}

.bracket-container {
  display: flex !important;
  flex-direction: column !important;
  align-items: center !important;
  justify-content: center !important;
  width: 20%;
  margin-left: 5em;
}

.bracket-container:last-child {
  margin-right: 2em;
}

@media (max-width: 600px) {
  .bracket-container {
    width: 100%;
    margin-left: 0;
    margin-right: 1em;
  }
}
