.login-container {
  width: 100vw;
  height: 100vh;
  background: var(--primary-gradient);
  margin: 0 !important;
}

.login-form {
  min-height: 50%;
  min-width: 50%;
  text-align: center;
  justify-content: space-around;
  align-items: center;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .login-form {
    width: 80%;
    height: auto;
    padding: 4em 2em 2em 2em;
  }
}

.label {
  margin-bottom: 1em;
}

.input {
  width: 50%;
  margin-bottom: 1em;
  text-align: center;
}

.submit-inverted {
  width: 50%;
  height: auto;
  border: none !important;
  margin-bottom: 1em;
  color: var(--primary) !important;
  background-color: white !important;
}

.auth-tab-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 100%;
}

.nav-tab-container {
  height: auto
}

.logo-container {
  height: 8vh;
}