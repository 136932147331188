.groups-container {
  display: flex;
  flex-direction: row;
  margin: 1%;
  justify-content: center;
  align-content: flex-start;
  flex-wrap: wrap;
}

.group-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20%;
  margin: 2em;
}

@media (max-width: 600px) {
  .group-container {
    width: 90%;
    margin: 0;
    border-bottom: 1px solid var(--primary);
    margin-bottom: 1em;
  }
}

.group {
  width: 100%;
  margin: 1.5em 1em 1.5em 1em !important;
  border: 1px solid var(--primary)
}

.selected {
  background: var(--primary-gradient) !important;
  color: white !important;
  border: none !important;
}

.selected-score {
  background: transparent !important;
  color: white !important;
  border: none !important;
}


.match-player-name-left {
  width: 35%;
  justify-content: flex-start;
}

.match-player-name-right {
  width: 35%;
  justify-content: flex-end;
}

.confirmed-score {
  span {
    border: 1px solid green !important;
  }
  input {
    border: 1px solid green !important;
  }
}