.home-container {
  padding-top: 3em;
  height: 100%;
}

.home-filters-container {
  background-color: white;
  margin-left: 10%;
  margin-right: 10%;
  padding-left: 3em;
  padding-right: 3em;
  border-radius: 50px;
  border-bottom: 2px solid var(--secondary);
  background-color: white;
}

.events-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1em 4em 1em 4em;
}

.event-container {
  display: flex;
  flex-direction: column;
  border-bottom: solid 2px var(--secondary);
  padding-left: 5em;
  border-radius: 10px;
  margin-top: 1em;
  background-color: white;
  width: 75%;
}

.upper-event {
  margin-top: .5em;
  margin-bottom: .5em;
  padding-top: 1em;
  width: 100%;
  align-items: center;
}

.lower-event {
  width: 100%;
  padding-bottom: 1em;
}

.players-accordion {
  width: 25%;
}

.waitlist-accordion {
  width: 25%;
}

.description-accordion {
  width: 45%;
}

.event-description {
  margin-top: .5em;
  border-radius: 10px;
  color: black;
  padding: 1em
}

.players-container {
  padding: 1em;
  width: 40%;
  border-radius: 10px;
  margin-left: .5em;
}

.sign-up-container {
  display: flex;
  align-items: center;
  width: 40%;
  justify-content: center;
}

.event-sign-up-button {
  background-color: var(--accent) !important;
  border: none !important;
}

.contact-button {
  color: var(--primary) !important;
  border-color: var(--primary) !important;
}

.contact-button:hover {
  color: white !important;
  background: var(--primary-gradient) !important;
  border-color: var(--primary) !important;
}

.manager-info {
  background-color: transparent !important;
}

.managers-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.manager {
  margin-left: 1em;
  margin-right: 1em;
}

.name-organizer {
  color: white;
}

.location-time-container {
  display: flex;
  flex-direction: column;
  right: 0;
  max-width: 15%;
}

.event-form-container {
  height: 86vh;
}

.event-form {
  background-color: white;
  width: 60%;
}

@media (max-width: 768px) {
  .event-form {
    width: auto;
  }
}

.general-information-div {
  padding: 0 1em 1em 1em;
  border-bottom: 1px solid var(--secondary);
  margin-bottom: 1em;
}

.max-nr-column {
  padding-right: 0 !important;
  margin-top: 0.5rem !important;
}

.event-dashboard-container {
  padding: 2em;
  display: flex;
  justify-content: center;
}

@media (max-width: 768px) {
  .event-dashboard-container{
    padding:.5em;
  }
}

.event-tab-content {
  background-color: white;
  padding: 1em;
  border-radius: 12px;
}

.event-tab-content.info {
  width: 100%;
  background-color: transparent;
}

.event-tab-content.registration {
  width: 70%;
}

@media (max-width: 768px) {
  .event-tab-content.registration {
    width: auto;
  }
}

.event-tab-content.matchups {
  width: 100% !important;
}

.event-tab-content.play {
  width: 100% !important;
}

.nav-pills .nav-item .active {
  background: var(--primary-gradient) !important;
  color: white !important;
}

.nav-pills .nav-link {
  color: black !important;
}

.custom-navbar {
  color: var(--primary) !important;
  padding: 1em 2em 1em 2em !important;
  border-bottom: 1px solid var(--secondary);
  width: 100vw;
}

.player-card {
  width: 70%;
  justify-content: center !important;
  margin-bottom: 1em !important;
}

.player-card-button {
  cursor: pointer;
}

.player-card-button:hover {
  background: var(--primary-gradient);
  color: white;
}

.players-list {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-right: 2px solid var(--secondary);
  margin-bottom: 1em;
}

.wait-list {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rejected-players-container {
  margin: 1em 0em 1em 0em !important;
  padding-top: 1em ;
}

.rejected-players-accordion {
  display: flex;
  flex-wrap: wrap;
}

.custom-slide {
  width: 100% !important;
  height: 65vh !important;
}

.custom-caoursel {
  width: 100%;
}

.add-player-button {
  width: 90%;
  background-color: transparent !important;
  border: 1px solid black !important;
}

.matching-option-button {
  border-radius: 12px !important;
  width: 10em !important;
  background-color: var(--secondary) !important;
  border: 0 !important;
  color: black !important;
}

.matching-option-button-checked {
  background: var(--primary-gradient) !important;
  border-radius: 12px !important;
  width: 10em !important;
  color: white;
}

.slide-container {
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-evenly !important;
  align-items: center !important;
  height: 80%;
  margin-left: 20%;
  margin-right: 20%;
}

.detailed-event-container {
  padding: 2em !important;
  height: 100%;
}

.detailed-event-column {
  border-right: 1px solid var(--secondary);
  border-left: 1px solid var(--secondary);
  padding: 1em !important;
}

.detailed-event-column:last-child {
  border-right: none;
}

.detailed-event-column:first-child {
  border-left: none;
}

.detailed-players-container {
  line-height: 1.7em;
}

.detailed-register-form {
  background-color: white !important;
  border-radius: 10px;
  padding: .5em;
  margin: 1em;
  padding: 1em
}

.event-col-button {
  height: 100%;
  width: 100%;
  background-color: transparent !important;
  border: none !important;
  color: black !important;
  border-left: 1px solid var(--secondary) !important;
  border-top-right-radius: 12px !important;
  border-bottom-right-radius: 12px !important;
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.event-col-button:hover {
  background: var(--primary-gradient) !important;
  color: white !important;
}

.custom-carousel {
  height: 100% !important;
}

.text-editor {
  height: 25em !important;
  margin-bottom: 4em !important;
}

.filters-button {
  background: var(--primary-gradient) !important;
  border: none !important;
  color: white !important;
  width: 4em !important;
  margin: 1em;
}

.event-results-tab-container {
  width: 50vw;
  padding: 2em;
}



@media screen and (max-width: 768px){
  .home-container {
    padding: 0 !important;
  }

  .mobile-filter-field {
    width: 80% !important;
    margin: 1em !important;
  }

  .mobile-event-container {
    display: flex;
    flex-direction: column;
    border: solid 1px var(--primary);
    border-radius: 10px;
    margin-top: 1em;
    background-color: white;
    width: 90%;
  }

  .mobile-events-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: .5em 1em .5em 1em !important;
  }

  .upper-event {
    margin-top: .5em;
    margin-bottom: .5em;
    padding-top: 1em;
    width: 100%;
    align-items: center;
  }

  .mobile-name-organizer {
    color: white;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 1em;
  }

  .sign-up-button {
    background-color: var(--accent) !important;
    border: none !important;
    padding-right: 1em !important;
    padding-left: 1em !important;
  }

  .contact-button {
    color: var(--primary) !important;
    border-color: var(--primary) !important;
    padding-right: 1em !important;
    padding-left: 1em !important;
  }

  .mobile-players-drawer-button {
    background-color: var(--white) !important;
    border: none !important;
    color: var(--primary) !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 0px !important;
  }

  .mobile-event-player-col {
    border-top: 1px solid var(--primary) !important;
    border-bottom: 1px solid var(--primary) !important;
    margin: 0 !important;
    padding: 0 !important;
    height: 5em;
  }

  .mobile-event-player-col:last-child {
    border-left: 1px solid var(--primary) !important;
  }

  .mobile-event-player-row {
    margin: 0 !important;
    padding: 0 !important;
  }

  .event-col-button {
    border-top-right-radius: 0px !important;
    border-bottom-right-radius: 8px !important;
    border-top-left-radius: 0px !important;
    border-bottom-left-radius: 8px !important;
    border: none !important;
  }
}
